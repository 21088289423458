.TermsConditions {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 400px)
}

.TermsConditions .TermsConditionsInnerContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.TermsConditions .TermsConditionsContent {
    width: 92%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 1%; 
    padding-left: 3%;
    padding-right: 3%; 
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
}