.Footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background: var(--primary-orange);
    margin-top: 40px;
}

.Footer .FooterInnerContainer {
    width: 100%;
    max-width: calc(1200px - 6%);
    display: flex;
    padding-left: 3%;
    padding-right: 3%;
}

.Footer .FooterRow {
    width: 100%;
    display: flex;
    flex-direction: row;
}

.Footer .FooterLinks {
    flex: 2;
    display: flex;
}

.Footer .FooterLinks a {
    text-decoration: none;
    color: #FFFFFF;
    margin-right: 20px;
    cursor: pointer;
    transition: 0.3s;
}

.Footer .FooterLinks a:hover {
    color: #000000;
}

.Footer .FooterCopyright {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    color: #FFFFFF;
}

.Medium .Footer {
    height: 150px;
}

.Medium .Footer .FooterRow {
    flex-direction: column;
}

.Medium .Footer .FooterLinks {
    flex-direction: column;
    align-items: center;
}

.Medium .Footer .FooterLinks a {
    margin-right: 0;
    margin-bottom: 10px;
}

.Medium .Footer .FooterCopyright {
    justify-content: center;
}