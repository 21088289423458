.ResetPassword {
    width: 100%;
    display: flex;
    justify-content: center;
    min-height: calc(100vh - 400px)
}

.ResetPassword .ResetPasswordInnerContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.ResetPassword .ResetPasswordContent {
    width: 92%;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 1%; 
    padding-left: 3%;
    padding-right: 3%; 
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.ResetPassword input {
    padding: 10px;
    font-size: 1.2rem;
    border: 1px solid #000000;
    border-radius: 10px;
}

.ResetPassword .ButtonContainer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.ResetPassword button {
    margin-top: 50px;
    width: 200px;
    padding: 15px;
    font-size: 1.2rem;
    font-weight: 600;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
    background-color: var(--primary-orange);
    transition: 0.3s
}

.ResetPassword button:hover {
    background-color: var(--primary-purple);
}

.ResetPassword .ErrorContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 40px;
}

.ResetPassword .SuccessContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    color: green;
    font-weight: 500;
    font-size: 1.1rem;
    margin-top: 40px;
}

.ResetPassword .LoadingContainer {
    display: flex;
    justify-content: center;
    margin-top: 40px;
}