.Home {
    width: 100%;
    display: flex;
    justify-content: center;
}

.Home .HomeInnerContainer {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}