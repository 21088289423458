.DownloadSection {
    width: 100%;
    display: flex;
    justify-content: center;
}

.DownloadSection .DownloadInnerSection {
    width: 100%;
    max-width: 1200px;
    display: flex;
    flex-direction: column;
}

.DownloadSection .FooterDownload {
    width: 92%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 1%; 
    padding-left: 3%;
    padding-right: 3%; 
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.DownloadSection .FooterDownload .TextSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.DownloadSection .FooterDownload .TextSection h2 {
    font-size: 2.5rem;
}

.DownloadSection .FooterDownload .TextSection > span {
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.DownloadSection .FooterDownload .ButtonSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.DownloadSection .FooterDownload .ButtonSection a {
    width: 200px;
}

.DownloadSection .FooterDownload .ButtonSection img {
    width: 100%;
}

.Medium .DownloadSection .FooterDownload {
    flex-direction: column;
}

.Medium .DownloadSection .FooterDownload .TextSection {
    align-items: center;
}

.Small .DownloadSection .FooterDownload .TextSection h2 {
    font-size: 2rem;
    text-align: center;
}