.Home .ProgressSection {
    width: 92%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 1%; 
    padding-left: 3%;
    padding-right: 3%; 
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.Home .ProgressSection .TextSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.Home .ProgressSection .TextSection h2 {
    font-size: 2.5rem;
}

.Home .ProgressSection .TextSection > span {
    font-size: 1.4rem;
    margin-bottom: 30px;
}

.Home .ProgressSection .TextSection div {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.Home .ProgressSection .TextSection div img {
    margin-right: 10px;
}

.Home .ProgressSection .TextSection div span {
    font-size: 1.3rem;
    font-weight: 600;
}

.Home .ProgressSection .ImageSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home .ProgressSection .ImageSection img {
    width: 40%;
}

.Medium .Home .ProgressSection {
    flex-direction: column;
}

.Medium .Home .ProgressSection .TextSection {
    margin-bottom: 40px;
}

.Small .Home .ProgressSection .TextSection h2 {
    font-size: 2rem;
}

.Small .Home .ProgressSection .ImageSection img {
    width: 70%;
}