.UnfoldedMenu {
    position: fixed;
    top: 0;
    left: 0;

    overflow: hidden;

    width: calc(100% - 6%);
    height: 100vh;

    display: flex;
    flex-direction: column;
    background-color: var(--primary-orange);

    padding-left: 3%;
    padding-right: 3%;
}

.UnfoldedMenu .Menu {
    display: flex;
    align-items: center;
    width: 100%;
    height: 80px;
}

.UnfoldedMenu .MenuLogo {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.UnfoldedMenu .MenuIcon {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.UnfoldedMenu .MenuIcon img {
    height: 40px;
    cursor: pointer;
}

.UnfoldedMenu .Link {
    padding-bottom: 15px;
    padding-top: 15px;
    border-bottom: 1px solid #FFFFFF;
}

.UnfoldableMenu .Link:hover {
    color: #000000;
}