.Navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background: var(--primary-orange);
    margin-bottom: 20px;
}

.Navigation .NavigationInnerContainer {
    width: 100%;
    max-width: calc(1200px - 6%);
    display: flex;
    padding-left: 3%;
    padding-right: 3%;
}

.Navigation .LogoContainer {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.Navigation .LogoImage {
    height: 55px;
}

.Navigation .MenuContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.Navigation .Link {
    text-decoration: none;
    color: #FFFFFF;
    font-weight: 600;
    font-size: 1.2rem;
    transition: 0.3s
}

.Navigation .Link:hover {
    color: #000000;
}

.Navigation .SpaceLink {
    padding-right: 20px;
}
 
.Navigation .MobileMenuContainer {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
} 

.Navigation .MobileMenuContainer img {
    height: 40px;
    cursor: pointer;
}

/* Mobile Menu Transition Animation */
.Navigation .MenuTransition-enter {
    width: calc(100% - 6%);
    height: 0;
}

.Navigation .MenuTransition-enter-active {
    width: calc(100% - 6%);
    height: 100vh;
    transition: 100ms ease-in-out;
}

.Navigation .MenuTransition-enter-done {
    width: calc(100% - 6%);
    height: 100vh;
}

.Navigation .MenuTransition-exit {
    width: calc(100% - 6%);
    height: 100vh;
}

.Navigation .MenuTransition-exit-active {
    width: calc(100% - 6%);
    height: 0;
    transition: 100ms ease-in-out;
}

.Navigation .MenuTransition-exit-done {
    width: calc(100% - 6%);
    height: 0;
}