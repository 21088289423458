.Home .HeaderSection {
    width: 92%;
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    margin-right: 1%;
    margin-left: 1%; 
    padding-left: 3%;
    padding-right: 3%; 
    padding-top: 40px;
    padding-bottom: 40px;
    background-color: #FFFFFF;
    border-radius: 20px;
}

.Home .HeaderSection .TextSection {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.Home .HeaderSection .TextSection h1 {
    font-size: 3rem;
}

.Home .HeaderSection .TextSection .ColoredHeader {
    color: var(--primary-purple);
}

.Home .HeaderSection .TextSection .SecondHeadline {
    font-size: 1.4rem;
}

.Home .HeaderSection .TextSection h3 {
    font-size: 1.6rem;
}

.Home .HeaderSection .TextSection a {
    width: 200px;
    cursor: pointer;
}

.Home .HeaderSection .TextSection a img {
    width: 100%;
    cursor: pointer;
}

.Home .HeaderSection .ImageSection {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.Home .HeaderSection .ImageSection img {
    width: 70%;
}

.Medium .Home .HeaderSection {
    flex-direction: column;
}

.Medium .Home .HeaderSection .TextSection {
    align-items: center;
    margin-bottom: 40px;
}

.Medium .Home .HeaderSection .TextSection h1 {
    text-align: center;
}

.Medium .Home .HeaderSection .TextSection span {
    text-align: center;
}

.Medium .Home .HeaderSection .ImageSection img {
    width: 50%;
}

.Small .Home .HeaderSection .TextSection h1 {
    font-size: 2.5rem;
}

.Small .Home .HeaderSection .ImageSection img {
    width: 80%;
}