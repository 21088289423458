.App h1 {
    font-size: 3rem;
}

.Small h1 {
    font-size: 2.5rem;
}

.App h2 {
    font-size: 2.5rem;
}

.Small h2 {
    font-size: 2rem;
}

.App h3 {
    font-size: 1.6rem;
}

.App h4 {
    font-size: 1.3rem;
}